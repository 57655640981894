import classNames from "classnames";
import { useI18n } from "../../hooks/useI18n";
import { usePagination } from "../../index";
export const Pagination = ({ data, onChange }) => {
    const { $t } = useI18n();
    const { isFirstPage, isLastPage, paginationList, currentPage } = usePagination(data);
    const pageurl = data.properties.pageurl;
    return (<div className="flex items-center justify-center pt-2.5 text-sm">
            <div className={classNames("self-center px-3 text-13", isFirstPage ? "text-grey-sand pointer-events-none cursor-not-allowed" : "cursor-pointer text-black")} onClick={() => onChange(`${pageurl}&tn_p=${currentPage - 1}`)}>
                <span className={classNames(!isFirstPage ? "text-gray-dracula" : "")}>{$t("Previous")}</span>
            </div>
            <ul>
                {paginationList.map((i) => (<li className={classNames("inline-block cursor-pointer p-3 text-black", {
                "pointer-events-none": i === currentPage,
            })} key={i} onClick={() => onChange(`${pageurl}&tn_p=${i}`)}>
                        <span className={classNames("pointer-events-none inline-block cursor-pointer", {
                "border-b border-black": i === currentPage,
            })}>
                            {i}
                        </span>
                    </li>))}
            </ul>
            <div className={classNames("self-center px-3 text-13", isLastPage ? "text-grey-sand pointer-events-none cursor-not-allowed" : "cursor-pointer text-black")} onClick={() => onChange(`${pageurl}&tn_p=${currentPage + 1}`)}>
                <span className={classNames(!isLastPage ? "text-gray-dracula" : "")}>{$t("next one")}</span>
            </div>
        </div>);
};
