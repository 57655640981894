import { Arrow } from "@jog/react-components";
export const Sorting = ({ onChange, sortfields, }) => {
    var _a, _b;
    if (!sortfields)
        return <></>;
    return (<div className="relative flex w-full flex-wrap">
            <select className="w-full appearance-none border border-gray-medium p-3 text-base text-gray-base outline-0 lg:w-auto lg:min-w-[250px]" onChange={(event) => onChange(event.target.value)} value={((_a = sortfields === null || sortfields === void 0 ? void 0 : sortfields.find((i) => i.isselected)) === null || _a === void 0 ? void 0 : _a.url) || ((_b = sortfields[0]) === null || _b === void 0 ? void 0 : _b.url)}>
                {sortfields.map((item, index) => (<option value={item.url} key={index}>
                        {item.displaytitle}
                    </option>))}
            </select>
            <Arrow color="black" className="absolute right-5 top-1/2 z-[1] -translate-y-1/2" direction="down"/>
        </div>);
};
