import prismicColors from "@/lib/prismicio/prismic-colors.json";
import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { useFacetExcessive } from "@jog/react-components";
import { colorLanguageConverter } from "@jog/shared";
import classNames from "classnames";
import { useState } from "react";
const Input = ({ checked, onChange, ...props }) => {
    const [_checked, setChecked] = useState(checked);
    return (<input type="checkbox" checked={_checked} onChange={(e) => {
            setChecked((v) => !v);
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        }} {...props}/>);
};
export const CheckboxFacet = ({ pending, onChange }) => {
    const textConvert = useTextConversionMethod();
    const { display, Showmore, facet } = useFacetExcessive();
    if (!facet)
        return;
    return (<>
            <ul>
                {facet.attributes.map((attribute, index) => (<li key={attribute.url} className={classNames("py-1", display(index))}>
                        <label className="flex items-center">
                            <Input type="checkbox" disabled={pending} value={attribute.title} checked={attribute.isselected} onChange={() => attribute.url && (onChange === null || onChange === void 0 ? void 0 : onChange(attribute.url))}/>
                            {facet.facetsettings.title === "Kleur" && (<div className="mr-1.25 inline-block h-4.5 w-4.5 cursor-pointer border border-gray-silver" style={{
                    backgroundColor: colorLanguageConverter(attribute.title, prismicColors),
                }}/>)}
                            <span>{textConvert(attribute.title)}</span>
                            <span className="inline-block pl-1.25 text-sm text-gray-main">
                                ({attribute.nrofresults})
                            </span>
                        </label>
                    </li>))}
            </ul>
            <Showmore className="mt-1.25 py-1 text-base lg:hover:text-red-mona"/>
        </>);
};
