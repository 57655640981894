export const Filter = (props) => (<svg id="filter-icon" data-name="filter-icon" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 27.47 14.75" {...props}>
        <defs>
            <clipPath transform="translate(-1 -8.61)">
                <rect x="1" y="8.61" width="27.47" height="14.75"/>
            </clipPath>
        </defs>
        <g>
            <path d="M25.1,14.63A1.35,1.35,0,1,0,26.46,16a1.36,1.36,0,0,0-1.36-1.35m-2.39.8a2.41,2.41,0,0,1,2.46-1.88,2.39,2.39,0,0,1,2.35,1.88h.93v1.09c-.2,0-.41,0-.61,0a.36.36,0,0,0-.43.29,2.41,2.41,0,0,1-3.88,1,3.19,3.19,0,0,1-.69-1,.41.41,0,0,0-.44-.29H1V15.43Z" transform="translate(-1 -8.61)"/>
            <path d="M4.77,11.05A1.36,1.36,0,0,0,6.1,12.39,1.37,1.37,0,0,0,7.45,11a1.34,1.34,0,1,0-2.68,0M1,11.54v-1H3.7A2.42,2.42,0,0,1,6.09,8.61a2.42,2.42,0,0,1,2.42,1.88H28.44v1.1H8.87a.42.42,0,0,0-.49.32,2.42,2.42,0,0,1-4.55,0,.38.38,0,0,0-.44-.3H1.09l-.09,0" transform="translate(-1 -8.61)"/>
            <path d="M13.5,22.26a1.35,1.35,0,1,0,0-2.69,1.35,1.35,0,0,0,0,2.69m15-1.9v.8c0,.09,0,.17,0,.3H16.24c-.25,0-.38.05-.47.31a2.42,2.42,0,0,1-4.55,0,.41.41,0,0,0-.47-.3H1v-1.1h9.73a.41.41,0,0,0,.47-.31,2.42,2.42,0,0,1,4.54,0,.44.44,0,0,0,.49.33H28.47" transform="translate(-1 -8.61)"/>
        </g>
    </svg>);
