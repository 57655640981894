import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from "react";
import { useI18n } from "../../hooks";
import { PriceSlider } from "../Slider";
import { useFacetExcessive } from "./index";
const SliderFacetContext = createContext({
    setFacet: () => { },
    range: [],
    setDrag: () => { },
    onUpdate: () => { },
    inputMinValues: "",
    inputMaxValues: "",
    setInputMinValues: () => { },
    setInputMaxValues: () => { },
    setDefaultValDelay: () => { },
    sliderValues: [],
    setPageUrl: () => { },
});
export const SliderFacetProvider = ({ onChange, children, }) => {
    const [pageUrl, setPageUrl] = useState("");
    const [facet, setFacet] = useState(null);
    const { range, values } = useMemo(() => getPrices((facet === null || facet === void 0 ? void 0 : facet.attributes) || []), [facet]);
    const timer = useRef();
    const [sliderValues, setSliderValues] = useState(values);
    const [inputMinValues, setInputMinValues] = useState("");
    const [inputMaxValues, setInputMaxValues] = useState("");
    const [drag, setDrag] = useState(false);
    const setDefaultValDelay = useCallback(() => {
        setSliderValues([toCent(inputMinValues), toCent(inputMaxValues)]);
    }, [inputMinValues, inputMaxValues]);
    const onUpdate = useCallback((values) => {
        setInputMinValues(`${(values[0] / 100).toFixed(2)}`);
        setInputMaxValues(`${(values[1] / 100).toFixed(2)}`);
        setSliderValues([...values]);
    }, []);
    useEffect(() => {
        onUpdate(values);
    }, [values, onUpdate]);
    useEffect(() => {
        timer.current && clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if (!facet || !pageUrl)
                return;
            if (drag)
                return;
            if (sliderValues.join("-") === values.join("-"))
                return;
            const qs = new URLSearchParams(pageUrl);
            const key = `tn_fk_${facet.facetsettings.urlkey}`;
            const value = sliderValues
                .map((i) => (i / 100).toFixed(2))
                .slice(0, 2)
                .join("-");
            qs.set(key, value);
            onChange(qs.toString());
        }, 200);
        return () => {
            timer.current && clearTimeout(timer.current);
        };
    }, [facet, sliderValues, onChange, values, drag, pageUrl]);
    return (<SliderFacetContext.Provider value={{
            setFacet,
            range,
            setDrag,
            onUpdate,
            inputMinValues,
            inputMaxValues,
            setInputMinValues,
            setInputMaxValues,
            setDefaultValDelay,
            sliderValues,
            setPageUrl,
        }}>
            {children}
        </SliderFacetContext.Provider>);
};
const STEP = 1;
const parse = (value, origin) => {
    const _value = +value;
    if (isNaN(_value))
        return `${origin}`;
    return `${value}`;
};
const toCent = (price) => Math.floor((+price * 1000) / 10);
const getPrices = (attributes) => {
    const values = attributes.slice(0, 2).map((i) => toCent(i.title));
    return {
        range: attributes.length >= 4 ? attributes.slice(2, 4).map((i) => toCent(i.title)) : [...values],
        values,
    };
};
export const SliderFacet = ({ pageUrl }) => {
    const { $t } = useI18n();
    const { facet } = useFacetExcessive();
    const { range, setDrag, onUpdate, inputMinValues, inputMaxValues, setInputMinValues, setInputMaxValues, setDefaultValDelay, sliderValues, setPageUrl, setFacet, } = useContext(SliderFacetContext);
    useEffect(() => {
        setPageUrl(pageUrl);
    }, [pageUrl, setPageUrl]);
    useEffect(() => {
        setFacet(facet);
    }, [facet, setFacet]);
    if (!facet)
        return <></>;
    if (range[0] === range[1]) {
        return <div>{$t("No avaialbe price range")}</div>;
    }
    return (<div className="w-full px-4 lg:pl-0">
            <PriceSlider setDrag={setDrag} step={STEP} onUpdate={onUpdate} range={range} values={sliderValues}/>
            <div className="mt-2 flex justify-between">
                <input className="h-[46px] w-[72px] appearance-none border border-gray-medium bg-white px-3 py-2.75 text-center text-sm text-black" type="text" value={inputMinValues} onChange={(e) => setInputMinValues((v) => parse(e.target.value, v))} onBlur={() => setDefaultValDelay()} onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.currentTarget.blur()}/>
                <input className="h-[46px] w-[72px] appearance-none border border-gray-medium bg-white px-3 py-2.75 text-center text-sm text-black" type="text" value={inputMaxValues} onChange={(e) => setInputMaxValues((v) => parse(e.target.value, v))} onBlur={() => setDefaultValDelay()} onKeyDown={(e) => e.key.toLowerCase() === "enter" && e.currentTarget.blur()}/>
            </div>
        </div>);
};
