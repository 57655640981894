import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { createContext, useCallback, useContext, useState } from "react";
import { Arrow } from "../Arrow/Arrow";
import { ContentLoader } from "../ContentLoader/ContentLoader";
export const FacetSkeletons = () => (<>
        {Array(3)
        .fill(0)
        .map((_, index) => (<div key={index} className="mb-5">
                    <ContentLoader className="mb-2.5 !h-10"/>
                    {Array(5)
            .fill(0)
            .map((_, index) => (<ContentLoader key={index} className="mb-2.5 !h-6"/>))}
                </div>))}
    </>);
const FacetExcessiveContext = createContext({
    display: () => "hidden",
    Showmore: () => <></>,
    facet: null,
});
export const FacetProvider = ({ facet, children, className, expand, }) => {
    const [displayExcessiveItem, setDisplayExcessiveItem] = useState(false);
    const display = useCallback((index) => !displayExcessiveItem && index >= facet.facetsettings.nrofshownattributes ? "hidden" : "block", [displayExcessiveItem, facet.facetsettings.nrofshownattributes]);
    const Showmore = ({ className }) => facet.attributes.length > facet.facetsettings.nrofshownattributes ? (<button onClick={() => setDisplayExcessiveItem((v) => !v)} className={className}>
                {displayExcessiveItem
            ? `${facet.facetsettings.collapsetext}...`
            : `${facet.facetsettings.expandtext}...`}
            </button>) : (<></>);
    const [state, setState] = useState(expand);
    return (<FacetExcessiveContext.Provider value={{ display, Showmore, facet }}>
            <AnimatePresence>
                <div className="text-black">
                    <div className={classNames(className, "pointer relative flex items-center justify-between uppercase")} onClick={() => setState((v) => !v)}>
                        {facet.facetsettings.title}
                        <Arrow color="black" className="mr-2 flex-shrink-0 transition-all duration-300" direction={state ? "down" : "up"}/>
                    </div>
                    <motion.div className="overflow-hidden text-left" transition={{ duration: 0.3, ease: [0.55, 0.055, 0.675, 0.19] }} animate={state ? "open" : "close"} variants={{ open: { height: "auto", padding: "0 0 40px 0" }, close: { height: 0, padding: 0 } }} initial={expand ? {} : { height: 0 }}>
                        {children}
                    </motion.div>
                </div>
            </AnimatePresence>
        </FacetExcessiveContext.Provider>);
};
export const useFacetExcessive = () => useContext(FacetExcessiveContext);
