import { getGroup } from "@jog/shared";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
export const useNavigation = (fetch) => {
    const [pending, setPending] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [group, setGroup] = useState({});
    const router = useRouter();
    const navigation = useCallback(async (search) => {
        setPending(true);
        try {
            const res = await fetch(search).then((res) => res.body);
            const parents = res.items
                .map((items) => { var _a, _b; return (_b = (_a = items.attributes.find((attribute) => attribute.name === "parent")) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b[0]; })
                .filter((i) => i && i !== "-");
            const groupId = Array.from(new Set(parents)).join("%7C");
            if (groupId) {
                await getGroup(groupId).then((res) => setGroup(res.body));
            }
            setResponse(res);
            return res;
        }
        catch (e) {
            setError(e);
        }
        finally {
            setPending(false);
            setLoaded(true);
        }
    }, [fetch]);
    useEffect(() => {
        var _a, _b;
        const path = (_b = (_a = response === null || response === void 0 ? void 0 : response.redirects) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url;
        path && router.push(path);
    }, [response === null || response === void 0 ? void 0 : response.redirects, router]);
    return {
        pending,
        error,
        response,
        navigation,
        group,
        loaded,
    };
};
