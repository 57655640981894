import { typedBoolean } from "@jog/shared";
import { useMemo } from "react";
import { useLastLister } from "./useLastLister";
export const breadcrumbSingle = (slug) => [{ text: slug, href: `/${slug}` }];
export const breadcrumbArray = (slugs) => {
    return slugs.reduce((obj, slug) => {
        const cur = `${obj.cur}${slug}/`;
        return { cur, paths: [...obj.paths, { text: slug, href: cur }] };
    }, { cur: "/", paths: [] }).paths;
};
export const genListerBreadcrumb = (slugs) => {
    const index = slugs.findIndex((i) => i.includes("_"));
    const _slugs = index === -1 ? slugs : slugs.slice(0, index);
    return breadcrumbArray(_slugs);
};
export const usePdpBreadcrumb = (slugs, specialCategories) => {
    const { path: lastLister } = useLastLister();
    return useMemo(() => {
        const base = lastLister.length && specialCategories.some((category) => lastLister.includes(category))
            ? [...lastLister.split("/").filter(typedBoolean), ...slugs.slice(-2)]
            : slugs;
        const paths = breadcrumbArray(base);
        paths.splice(-2, 1);
        return paths;
    }, [lastLister, slugs, specialCategories]);
};
