import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { Link, LinkFacetProvider, useFacetExcessive, useLinkFacet } from "@jog/react-components";
import classNames from "classnames";
const Lv1 = ({ item, className }) => {
    var _a;
    const { href, label } = useLinkFacet(item, 1);
    return (<li className={classNames("mt-3.75 mb-1.5 first:-mt-px", className)}>
            <Link href={href}>{label}</Link>
            <ul>
                {(_a = item.children) === null || _a === void 0 ? void 0 : _a.map((child, index) => (<Lv2 key={index} item={child}/>))}
            </ul>
        </li>);
};
const Lv2 = ({ item }) => {
    var _a;
    const { href, label } = useLinkFacet(item, 2);
    return (<li>
            <Link href={href} className="mt-3.75 mr-0 mb-1.5 ml-1.5 block">
                {label}
            </Link>
            <ul>
                {(_a = item.children) === null || _a === void 0 ? void 0 : _a.map((child, index) => (<Lv3 key={index} item={child}/>))}
            </ul>
        </li>);
};
const Lv3 = ({ item }) => {
    const { href, label } = useLinkFacet(item, 3);
    return (<li>
            <Link href={href} className="mt-3.75 mr-0 mb-1.5 ml-3 block">
                {label}
            </Link>
        </li>);
};
export const LinkFacet = ({ path }) => {
    const textConvert = useTextConversionMethod();
    const { display, Showmore, facet } = useFacetExcessive();
    if (!facet)
        return;
    return (<LinkFacetProvider path={path} textConversion={textConvert} type={facet.facetsettings.selectiontype}>
            <ul>
                {facet.attributes.map((attribute, index) => (<Lv1 key={index} item={attribute} className={display(index)}/>))}
            </ul>
            <Showmore className="mt-1.25 py-1 text-base lg:hover:text-red-mona"/>
        </LinkFacetProvider>);
};
