export const calcPagination = (show = 5) => {
    if (show % 2 === 0)
        throw new Error("Show should be odd");
    return (cur, total) => {
        const range = Math.floor(show / 2);
        const arr = Array(total)
            .fill(undefined)
            .map((_, i) => i + 1);
        if (total < show)
            return arr;
        return arr.filter((index) => {
            if (cur <= range)
                return index <= show;
            if (total - cur <= range)
                return index >= total - show;
            return Math.abs(index - cur) <= range;
        });
    };
};
export const usePagination = (data) => {
    const nrofpages = data.properties.nrofpages;
    const currentPage = data.properties.currentpage;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === nrofpages;
    const paginationList = calcPagination(5)(currentPage, nrofpages);
    return {
        isFirstPage,
        isLastPage,
        paginationList,
        currentPage,
    };
};
