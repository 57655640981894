import classNames from "classnames";
import { kebabCase } from "lodash-es";
import { useRouter } from "next/router";
import { createContext, useContext, useMemo } from "react";
const LinkFacetContext = createContext({
    slugs: [],
    type: "",
    textConversion: (v) => v,
    isBrandsCategoryPage: false,
});
export const LinkFacetProvider = ({ path, type, textConversion, children, }) => {
    var _a;
    const slugs = ((_a = path === null || path === void 0 ? void 0 : path.replace(/^\//, "")) === null || _a === void 0 ? void 0 : _a.split("/")) || [];
    const isBrandsCategoryPage = !!(path === null || path === void 0 ? void 0 : path.includes("merken"));
    return (<LinkFacetContext.Provider value={{ slugs, type, textConversion, isBrandsCategoryPage }}>
            {children}
        </LinkFacetContext.Provider>);
};
const useLinkFacetContext = () => useContext(LinkFacetContext);
const removeWordsFromCategoryName = (name, numberOfWords = 1) => {
    if (!name)
        return "";
    const nameArray = name.split(" ");
    if (nameArray.length <= 1)
        return name;
    return nameArray.slice(numberOfWords, Number(nameArray.length)).join(" ");
};
export const useLinkFacet = (item, numberOfWords) => {
    const { slugs, type, textConversion, isBrandsCategoryPage } = useLinkFacetContext();
    const router = useRouter();
    const title = numberOfWords === 1 && isBrandsCategoryPage
        ? item.title
        : removeWordsFromCategoryName(item.title, numberOfWords);
    const isSearchPage = useMemo(
    /* Pathname for search set up to root, while pathname for everything else setup to the last instance */
    () => router.pathname === "/search" && numberOfWords === 1, [numberOfWords, router.pathname]);
    const hrefPrefix = isSearchPage ? "/" : `/${slugs.slice(0, numberOfWords).join("/")}/`;
    return {
        href: `${hrefPrefix}${kebabCase(title)}`,
        label: (<span className={classNames({ "font-bold": item.isselected })}>
                {`${numberOfWords === 1 ? "" : "- "}${textConversion(type === "tree" ? title : item.title)}`}
            </span>),
    };
};
